<template>
  <div class="budget-wrapper-outer">
    <vue-custom-scrollbar class="scroll-area">
    <div class="budget-wrapper">
      <div class="budget-wrapper__left">
        <div class="annual-budget-info">
          <h3>वार्षिक बजेट</h3>
          <span>रु. {{nepaliNumberFormat(annual_budget, "ne")}}</span>
        </div>
        <div class="annual-budget-chart">
          <h3
            class="annual-budget-chart__title"
          >वर्गबाट विभाजीत विकास कार्यक्रम तथा कार्यक्षेत्रगत बजेट</h3>
          <div class="annual-budget-chart__chart">
            <BarChart v-if="budgetChartData.length != 0" :barChartData="budgetChartData" />
            <div v-else class="annual-budget-chart__no-data">
              <NoDataFound/>
            </div>
          </div>
        </div>
      </div>
      <div class="budget-wrapper__right">
        <div class="program-info">
          <div class="program-info__card program-info-card program-info-card--social" @click="goToProgramList('social')">
            <div
              class="program-info-card__amount-percent"
            >
              <div>{{englishToNepaliNumber(sectorWiseBudget.socialBudget.percent)}}%</div>
            </div>
            <div class="program-info-card__right">
              <div class="program-info-card__desc">
                <h4>सामाजिक विकास</h4>
                <span>रु. {{nepaliNumberFormat(sectorWiseBudget.socialBudget.budget, "ne")}}</span>
              </div>
              <div class="program-info-card__programs-total">
                <h4>कार्यक्रम</h4>
                <span>{{englishToNepaliNumber(sectorWiseBudget.socialBudget.programs)}}</span>
              </div>
            </div>
          </div>
          <div class="program-info__card program-info-card program-info-card--financial" @click="goToProgramList('financial')">
            <div
              class="program-info-card__amount-percent"
            >
              <div>{{englishToNepaliNumber(sectorWiseBudget.financialBudget.percent)}}%</div>
            </div>
            <div class="program-info-card__right">
              <div class="program-info-card__desc">
                <h4>आर्थिक विकास</h4>
                <span>रु. {{nepaliNumberFormat(sectorWiseBudget.financialBudget.budget, "ne")}}</span>
              </div>
              <div class="program-info-card__programs-total">
                <h4>कार्यक्रम</h4>
                <span>{{englishToNepaliNumber(sectorWiseBudget.financialBudget.programs)}}</span>
              </div>
            </div>
          </div>
          <div class="program-info__card program-info-card program-info-card--institutional" @click="goToProgramList('institutional')">
            <div
              class="program-info-card__amount-percent"
            >
              <div>{{englishToNepaliNumber(sectorWiseBudget.institutionalBudget.percent)}}%</div>
            </div>
            <div class="program-info-card__right">
              <div class="program-info-card__desc">
                <h4>संस्थागत विकास</h4>
                <span>रु. {{nepaliNumberFormat(sectorWiseBudget.institutionalBudget.budget, "ne")}}</span>
              </div>
              <div class="program-info-card__programs-total">
                <h4>कार्यक्रम</h4>
                <span>{{englishToNepaliNumber(sectorWiseBudget.institutionalBudget.programs)}}</span>
              </div>
            </div>
          </div>
          <div class="program-info__card program-info-card program-info-card--infrastructure" @click="goToProgramList('infrastructure')">
            <div
              class="program-info-card__amount-percent"
            >
              <div>{{englishToNepaliNumber(sectorWiseBudget.infrastructureBudget.percent)}}%</div>
            </div>
            <div class="program-info-card__right">
              <div class="program-info-card__desc">
                <h4>पूर्वाधार विकास</h4>
                <span>रु. {{nepaliNumberFormat(sectorWiseBudget.infrastructureBudget.budget, "ne")}}</span>
              </div>
              <div class="program-info-card__programs-total">
                <h4>कार्यक्रम</h4>
                <span>{{englishToNepaliNumber(sectorWiseBudget.infrastructureBudget.programs)}}</span>
              </div>
            </div>
          </div>
          <div class="program-info__card program-info-card program-info-card--environment" @click="goToProgramList('environment')">
            <div
              class="program-info-card__amount-percent"
            >
              <div>{{englishToNepaliNumber(sectorWiseBudget.environmentDisasterBudget.percent)}}%</div>
            </div>
            <div class="program-info-card__right">
              <div class="program-info-card__desc">
                <h4>वातावरण तथा विपद व्यवस्थापन</h4>
                <span>रु. {{nepaliNumberFormat(sectorWiseBudget.environmentDisasterBudget.budget, "ne")}}</span>
              </div>
              <div class="program-info-card__programs-total">
                <h4>कार्यक्रम</h4>
                <span>{{englishToNepaliNumber(sectorWiseBudget.environmentDisasterBudget.programs)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="program-info-detail">
          <p
            class="program-info-detail__text"
          >वार्षिक विकास कार्यक्रम तथा कार्यक्षेत्रगत बजेट सम्बन्धित अधिक जानकारीका लागि</p>
          <a href="#" target="__blank" class="program-info-detail__btn">यहाँ जानुहोस्</a>
        </div>
      </div>
    </div>
    <footer class="footer">
      <unicon name="exclamation-circle"></unicon>मेल्लेख गाउँपालिकाका विभिन्न प्रणालीहरुमा रहेका आँकडालाई संग्रहित गरि यस प्रणालीमार्फत् प्रस्तुत गरिएकाे छ।
    </footer>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import { nepaliNumberFormat, englishToNepaliNumber } from "nepali-number";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  components: {
    BarChart: () => import("./BarChart"),
    NoDataFound: () => import("../../components/NoDataFound.vue"),
    vueCustomScrollbar
  },
  methods: {
    nepaliNumberFormat: nepaliNumberFormat,
    englishToNepaliNumber: englishToNepaliNumber,
    updateSectorWiseBudget: function () {
      let fiscalYear = this.$store.state.fiscal_year;
      let sectorBudget = this.$store.state.sectorBudget.filter((ele) => {
        return ele.fiscalyear === fiscalYear;
      });

      this.budgetChartData = sectorBudget.reduce((eleCollection, ele) => {
        let obj = {};
        obj.category = ele.category;
        obj.budget = ele.budget;
        eleCollection.push(obj);
        return eleCollection;
      }, []);

      this.sectorWiseBudget = {
        socialBudget: { budget: "0", programs: "0", percent: 0 },
        financialBudget: { budget: "0", programs: "0", percent: 0 },
        infrastructureBudget: {
          budget: "0",
          programs: "0",
          percent: 0
        },
        institutionalBudget: {
          budget: "0",
          programs: "0",
          percent: 0
        },
        environmentDisasterBudget: { budget: "0", programs: "0", percent: 0 }
      };

      for (const key in sectorBudget) {
        if (this.map[sectorBudget[key].category]) {
          let temp = this.map[sectorBudget[key].category];
          this.sectorWiseBudget[temp].budget = sectorBudget[key].budget;
          this.sectorWiseBudget[temp].programs =
            sectorBudget[key].programnumber;
          this.sectorWiseBudget[temp].percent = Math.round(
            (sectorBudget[key].budget / this.annual_budget) * 100
          );
        }
      }
    },
    goToProgramList: function(programName) {
      this.$router.push({ name: 'Program-list', params: { programName: programName } })
    }
  },
  data: function () {
    return {
      map: {
        "आर्थिक विकास": "financialBudget",
        "सामाजिक विकास": "socialBudget",
        "पूर्वाधार विकास": "infrastructureBudget",
        "संस्थागत विकास, सेवा प्रवाह र सुशासन": "institutionalBudget",
        "वातावरण तथा विपद व्यवस्थापन": "environmentDisasterBudget"
      },
      sectorWiseBudget: {
        socialBudget: {
          budget: 0,
          programs: 0,
          percent: 0
        },
        financialBudget: {
          budget: 0,
          programs: 0,
          percent: 0
        },
        infrastructureBudget: {
          budget: 0,
          programs: 0,
          percent: 0
        },
        institutionalBudget: {
          budget: 0,
          programs: 0,
          percent: 0
        },
        environmentDisasterBudget: {
          budget: 0,
          programs: 0,
          percent: 0
        }
      },
      budgetChartData: []
    };
  },
  watch: {
    sectorBudget: {
      handler: "updateSectorWiseBudget",
      immediate: true
    },
    fiscalYear: {
      handler: "updateSectorWiseBudget",
      immediate: true
    }
  },
  computed: {
    // budgetChartData: [],
    sectorBudget() {
      return this.$store.state.sectorBudget;
    },
    fiscalYear() {
      return this.$store.state.fiscal_year;
    },
    annual_budget() {
      let fiscalYear = this.$store.state.fiscal_year;

      let sectorBudget = this.$store.state.sectorBudget.filter((ele) => {
        return ele.fiscalyear === fiscalYear;
      });
      if (sectorBudget.length === 0) {
        return 0;
      }
      let annualBudget = sectorBudget.reduce((acc, ele) => {
        return acc + Number(ele.budget);
      }, 0);
      return annualBudget;
    }
  }
};
</script>

<style lang="scss" scoped>
.budget-wrapper {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  padding: 0 100px;

  @media screen and (max-width: 3839px) {
    padding: 0 70px;
  }

  @media screen and (max-width: 3199px) {
    padding: 0 36px;
  }

  &__left {
    width: 34.2817%;
    height: 100%;
    background-color: $neutrals-charcoal;
    padding: 80px;
    border-radius: 20px;

    &:hover {
      background-color: $neutrals-midnight;

      .annual-budget-info {
        border-bottom-color: $neutrals-charcoal;
      }
    }

    @media screen and (max-width: 3839px) {
      padding: 60px;
    }

    @media screen and (max-width: 3199px) {
        padding: 28px;
        border-radius: 8px;
    }
  }

  &__right {
    width: 64.5943%;
    display: flex;
    flex-direction: column;
  }

  &-outer {
    background-color: #05050f;
    position: relative;
    padding-bottom: 170px;

    @media screen and (max-width: 3199px) {
      height: calc(100vh - 108px);
      padding-bottom: 0;
      /* padding-bottom: 64px; */
    }
  }
}

.annual-budget-info {
  padding-bottom: 120px;
  border-bottom: 4px solid $neutrals-midnight;

  @media screen and (max-width: 3839px) {
    padding-bottom: 90px;
  }

  @media screen and (max-width: 3199px) {
    padding-bottom: 42px;
    border-width: 1px;
  }

  h3 {
    font-size: 60px;

    @media screen and (max-width: 3839px) {
      font-size: 50px;
    }

    @media screen and (max-width: 3199px) {
      font-size: 22px;
    }
  }

  span {
    font-size: 100px;
    font-weight: bold;
    color: $neutrals-white;

    @media screen and (max-width: 3839px) {
      font-size: 75px;
    }

    @media screen and (max-width: 3199px) {
      font-size: 36px;
    }
  }
}

.annual-budget-chart {
  padding: 120px 0 87px;

  @media screen and (max-width: 3839px) {
    padding: 55px 0 0;
  }

  @media screen and (max-width: 3199px) {
    padding: 42px 0 0;
  }

  &__title {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: $neutrals-white;

    @media screen and (max-width: 3839px) {
      font-size: 42px;
      line-height: 63px;
    }

    @media screen and (max-width: 3199px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &__no-data {
    min-height: 835px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 90px;

    @media screen and (max-width: 3199px) {
      min-height: 315px;
      font-size: 34px;
    }
  }
}

.program-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 32px;

  @media screen and (max-width: 3199px) {
    gap: 11px;
    margin-bottom: 11px;
  }

  &__card {
    padding: 75px 40px;
    background-color: $neutrals-charcoal;
    border-radius: 20px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $neutrals-midnight;
      box-shadow: 0px 25px 100px #191932;
      cursor: pointer;

      .program-info-card__programs-total {
        border-left-color: $neutrals-charcoal;
      }
    }

    @media screen and (max-width: 3839px) {
      padding: 60px 36px;
    }

    @media screen and (max-width: 3199px) {
      padding: 26px 22px;
      border-radius: 8px;
    }

    @media screen and (max-width: 1919px) {
      padding: 26px 14px;
    }
  }
}

.program-info-card {
    &--social {
      .program-info-card__amount-percent {
        @include bg-secondary-gradient-teal;
      }

      &:hover {
        .program-info-card__programs-total span {
          @include bg-secondary-gradient-teal;
          background-clip: text;
          -webkit-background-clip: text;
        }
      }
    }

    &--financial {
      .program-info-card__amount-percent {
        @include bg-secondary-gradient-peach;
      }

      &:hover {
        .program-info-card__programs-total span {
          @include bg-secondary-gradient-peach;
          background-clip: text;
          -webkit-background-clip: text;
        }
      }
    }

    &--institutional {
      .program-info-card__amount-percent {
        @include bg-secondary-gradient-icy;
      }

      &:hover {
        .program-info-card__programs-total span {
          @include bg-secondary-gradient-icy;
          background-clip: text;
          -webkit-background-clip: text;
        }
      }
    }

    &--infrastructure {
      .program-info-card__amount-percent {
        @include bg-secondary-gradient-lavendar;
      }

      &:hover {
        .program-info-card__programs-total span {
          @include bg-secondary-gradient-lavendar;
          background-clip: text;
          -webkit-background-clip: text;
        }
      }
    }

    &--environment {
      .program-info-card__amount-percent {
        @include bg-secondary-gradient-lily;
      }

      &:hover {
        .program-info-card__programs-total span {
          @include bg-secondary-gradient-lily;
          background-clip: text;
          -webkit-background-clip: text;
        }
      }
    }

  &__amount-percent {
    width: 188px;
    height: 188px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    @media screen and (max-width: 3839px) {
      width: 148px;
      height: 148px;
    }
     
    @media screen and (max-width: 3199px) {
      width: 71px;
      height: 71px;
    }

    & > div {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: $neutrals-charcoal;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      font-weight: 600;

      @media screen and (max-width: 3839px) {
        width: 130px;
        height: 130px;
        font-size: 40px;
      }

      @media screen and (max-width: 3199px) {
        width: 58px;
        height: 58px;
        font-size: 16px;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 188px);

    @media screen and (max-width: 3839px) {
      width: calc(100% - 148px);
    }

    @media screen and (max-width: 3199px) {
      width: calc(100% - 71px);
    }
  }

  &__desc {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 3199px) {
      padding: 0 16px;
    }

    @media screen and (max-width: 1919px) {
      padding: 0 8px;
    }

    h4 {
      font-size: 40px;

      @media screen and (max-width: 3839px) {
        font-size: 34px;
      }
      @media screen and (max-width: 3199px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    span {
      font-size: 60px;
      font-weight: 800;
      color: $neutrals-white;
      display: block;
      
      @media screen and (max-width: 3839px) {
        font-size: 48px;
      }
      @media screen and (max-width: 3839px) {
        font-size: 21px;
        line-height: 31.5px;
      }
    }
  }

  &__programs-total {
    border-left: 2px solid $neutrals-midnight;
    padding-left: 32px;

    @media screen and (max-width: 3199px) {
      padding-left: 16px;
    }

    @media screen and (max-width: 1919px) {
      padding-left: 12px;
    }

    h4 {
      font-size: 40px;

       @media screen and (max-width: 3839px) {
        font-size: 34px;
      }
      @media screen and (max-width: 3199px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    span {
      background: $neutrals-white;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 60px;
      font-weight: 800;
      /* color: $neutrals-white; */
      display: block;
      color: $neutrals-white;

      @media screen and (max-width: 3839px) {
        font-size: 48px;
      }
      @media screen and (max-width: 3839px) {
        font-size: 21px;
        line-height: 31.5px;
      }
    }
  }
}

.program-info-detail {
  /* height: calc(100% - 1110px); */
  background-color: $neutrals-charcoal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 1;

  &:hover {
    background-color: $neutrals-midnight;
  }

  @media screen and (max-width: 3199px) {
    border-radius: 8px;
  }

  &__text {
    margin-bottom: 40px;

    @media screen and (max-width: 3199px) {
      font-size: 16px;
      margin-bottom: 14px;
    }
  }

  &__btn {
    padding: 30px 262px;
    background-color: $neutrals-nightsky;
    border-radius: 20px;
    color: $neutrals-white;

    @media screen and (max-width: 3199px) {
      font-size: 14px;
      margin-bottom: 14px;
      padding: 11px 92px;
      border-radius: 7px;
    }

    &:hover {
      background-color: $secondary;
    }
  }
}

.footer {
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 47px 100px;
  /* position: absolute; */

  @media screen and (max-width: 3199px) {
    font-size: 14px;
    padding: 16px 36px;
  }

  .unicon {
    margin-right: 16px;

    @media screen and (max-width: 3199px) {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }
}
</style>
